<template>
  <div class="container-fluid">
    <div class="row justify-content-center mb-3">
      <div class="col-auto text-center">
        <p>{{ $t(`course.${type}_wizard.fourth_step_info`) }}</p>
      </div>
    </div>
    <div class="row justify-content-center">
      <div class="col-12 col-md-8">
        <card class="shadow-sm" header-classes="pb-0 " body-classes="standard-card-body">
          <div slot="header">
            <h4 class="card-title text-capitalize">{{ $t(`course.${type}_data`) }}</h4>
          </div>
          <ul class="list-group list-group-flush">
            <list-group-item-component
              :label="$t('fields.active')"
              :value="course.active ? $t('common.yes') : $t('common.no')"
            />
            <list-group-item-component
              :label="$t('fields.type')"
              :value="course.type ? $t(`common.${course.type}`) : 'N.D.'"
            />
            <list-group-item-component
              v-for="(key, index) in courseKeysToShow"
              v-bind:key="`course-keys-${index}`"
              :label="$t(`fields.${key}`)"
              :value="course[key]"
            />
            <list-group-item-component
              :label="$t('fields.enrollment_price')"
              :value="course.enrollment_price | euro"
            />
            <list-group-item-component
              :label="$t('fields.price')"
              :value="course.price | euro"
            />
          </ul>
        </card>
        <card
          class="shadow-sm"
          header-classes="pb-0"
          body-classes="standard-card-body"
          v-if="courses && courses.length > 0"
        >
          <div slot="header">
            <h4 class="card-title text-capitalize">{{ $t('page.courses') }}</h4>
          </div>
          <ul class="list-group list-group-flush">
            <list-group-item-component
              v-for="(course, index) in courses"
              v-bind:key="`selected-courses-${index}`"
              :label="course.code"
              :value="course.title"
            />
          </ul>
        </card>
      </div>
      <div class="col-12 col-md-4">
        <card class="shadow-sm" header-classes="pb-0 " body-classes="standard-card-body">
          <div slot="header">
            <h4 class="card-title text-capitalize">{{ $t('page.selected_course_modules') }}</h4>
          </div>
          <ul class="list-group list-group-flush">
            <li class="list-group-item text-center text-capitalize" v-if="courseModules.length === 0">
              {{ $t('common.empty_list') }}
            </li>
            <list-group-item-component
              v-for="(courseModule, index) in courseModules"
              v-bind:key="`selected-modules-${index}`"
              :label="courseModule.code"
              :value="courseModule.title"
            />
          </ul>
        </card>
        <card
          class="shadow-sm"
          header-classes="pb-0"
          body-classes="standard-card-body"
          v-if="tutors && tutors.length > 0"
        >
          <div slot="header">
            <h4 class="card-title text-capitalize">{{ $t('fields.tutors') }}</h4>
          </div>
          <ul class="list-group list-group-flush">
            <list-group-item-component
              v-for="tutor in tutors"
              v-bind:key="`selected-tutors-${tutor.id}`"
              :label="tutor.id | udid"
              :value="$options.filters.optional(users[tutor.id], 'firstname') + ' ' +
                      $options.filters.optional(users[tutor.id], 'lastname')"
            />
          </ul>
        </card>
      </div>
    </div>
  </div>
</template>

<script>
import Course from "@/models/course";
import BaseAlert from "@/components/BaseAlert";
import ListGroupItemComponent from "@/components/ListGroupItemComponent";
import {mapGetters} from "vuex";

export default {
  name: "CourseWizardFourthStep",
  components: {ListGroupItemComponent, BaseAlert},
  data() {
    return {
      courseKeysToShow: ['code', 'title', 'lesson_number', 'minimum_participants', 'description'],
      type: this.$route.params.type
    }
  },
  props: {
    course: {
      type: Object,
      default: () => Course
    },
    courses: {
      type: Array,
      default: () => []
    },
    tutors: {
      type: Array,
      default: () => []
    },
    courseModules: {
      type: Array,
      default: () => []
    }
  },
  computed: {
    ...mapGetters({
      allCourses: 'common/allCourses',
      users: 'common/users'
    })
  }
}
</script>

<style scoped>

</style>
