<template>
  <div class="container-fluid">
    <div class="row justify-content-center mb-3">
      <div class="col-auto text-center">
        <p>{{ $t(`course.${type}_wizard.second_step_info`) }}</p>
      </div>
    </div>
    <course-module-picker ref="courseModulePicker"/>
  </div>
</template>

<script>
import CourseModulePicker from "@/pages/Didactics/components/CourseModulePicker";

export default {
  name: "CourseWizardSecondStep",
  components: {CourseModulePicker},
  data() {
    return {
      type: this.$route.params.type
    }
  },
  methods: {
    getCourseModules() {
      return this.$refs.courseModulePicker.getCourseModules();
    },
  }
}
</script>

<style scoped>

</style>
