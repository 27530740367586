<template>
  <div class="container-fluid">
    <div class="row justify-content-center mb-3">
      <div class="col-auto text-center">
        <p>{{ $t(`course.${type}_wizard.third_step_info`) }}</p>
      </div>
    </div>
    <div class="row justify-content-center">
      <div class="col-12">
        <course-tutors-card :course="course" ref="courseTutorsCard"/>
      </div>
    </div>
  </div>
</template>

<script>
import Course from "@/models/course";
import BaseAlert from "@/components/BaseAlert";
import ListGroupItemComponent from "@/components/ListGroupItemComponent";
import {mapGetters} from "vuex";
import CourseTutorsCard from "@/pages/Courses/components/CourseTutorsCard";

export default {
  name: "CourseWizardThirdStep",
  components: {CourseTutorsCard, ListGroupItemComponent, BaseAlert},
  data() {
    return {
      type: this.$route.params.type
    }
  },
  props: {
    course: {
      type: Object,
      default: () => Course
    }
  },
  computed: {
    ...mapGetters({
      courses: 'common/allCourses',
      users: 'common/users'
    })
  },
  methods: {
    getTutors() {
      return this.$refs.courseTutorsCard.getSelectedTutors();
    }
  }
}
</script>

<style scoped>

</style>
