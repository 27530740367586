<template>
  <div class="container-fluid">
    <div class="row justify-content-center mb-3">
      <div class="col-auto text-center">
        <p>{{ $t(`course.${type}_wizard.first_step_title`) }}</p>
        <p>{{ $t(`course.${type}_wizard.first_step_subtitle`) }}</p>
      </div>
    </div>
    <div class="row">
      <div class="col-12">
        <course-form-card :course="course" ref="courseForm"/>
      </div>
      <div class="col-12" v-if="course.type === 'workshop'">
        <certificate-courses-card :course="course" ref="certificateCoursesCard"/>
      </div>
    </div>
  </div>
</template>

<script>
import Course from "@/models/course";
import CertificateCoursesCard from "@/pages/Courses/components/CertificateCoursesCard";
import CourseTutorsCard from "@/pages/Courses/components/CourseTutorsCard";
import CourseFormCard from "@/pages/Courses/components/CourseFormCard";

export default {
  name: "CourseWizardFirstStep",
  components: {CourseFormCard, CourseTutorsCard, CertificateCoursesCard},
  data() {
    return {
      course: this.$_.cloneDeep(Course),
      type: this.$route.params.type
    }
  },
  mounted() {
    this.$set(this.course, 'type', this.type || 'course');
  },
  methods: {
    validate() {
      return this.$refs.courseForm.validate();
    },
    getCourses() {
      return this.course.type === 'workshop' ? this.$refs.certificateCoursesCard.getSelectedCourses() : [];
    }
  }
}
</script>

<style scoped>

</style>
